var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-block", staticStyle: { width: "80%" } },
    [
      _c(
        "div",
        { staticClass: "sourcez" },
        [
          _vm.variations && _vm.variations.length
            ? _c(
                "el-form",
                {
                  ref: "formData",
                  staticClass: "demo-form",
                  attrs: {
                    model: _vm.variations[0],
                    "status-icon": "",
                    rules: _vm.rules,
                    "label-position": "left"
                  }
                },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.variations,
                        fit: "",
                        "highlight-current-row": ""
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "Variation Id",
                          width: "100px",
                          prop: "id"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: { align: "center", label: "Name", prop: "name" }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "Unit Price",
                          prop: "unitPrice"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("el-input-number", {
                                    attrs: {
                                      name: "unitPrice",
                                      size: "mini",
                                      precision: 2,
                                      step: 1
                                    },
                                    model: {
                                      value: scope.row.unitPrice,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "unitPrice", $$v)
                                      },
                                      expression: "scope.row.unitPrice"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2480778717
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          label: "Sale Price",
                          prop: "salePrice"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("el-input-number", {
                                    attrs: {
                                      name: "salePrice",
                                      size: "mini",
                                      precision: 2,
                                      step: 1
                                    },
                                    model: {
                                      value: scope.row.salePrice,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "salePrice", $$v)
                                      },
                                      expression: "scope.row.salePrice"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2440732733
                        )
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticStyle: { "text-align": "center", margin: "5px" } },
                    [
                      _vm.variations.length
                        ? _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loading,
                                  expression: "loading"
                                },
                                { name: "waves", rawName: "v-waves" }
                              ],
                              attrs: {
                                type: "success",
                                align: "center",
                                size: "mini"
                              },
                              on: { click: _vm.submitForm }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("global.form.save")) + " "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }